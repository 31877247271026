.thumbLink {
  margin-bottom: 30px;
}
.thumbContainer {
  display: flex;
  flex-direction: column;
  border-radius: 14px !important;
  width: calc(100% - 30px) !important;
  margin: 0 30px 30px 0 !important;
  cursor: pointer;
  z-index: 100;
  transition: width 1s;
}
/* .thumbContainer:hover {
  min-width: 350px;
} */
.image {
  border-radius: 14px !important;
}
.content {
  margin: 0 !important;
  padding: 0.5rem 1rem !important;
}
.title {
  /* white-space: nowrap; */
  /* text-overflow: ellipsis; */
  /* overflow: hidden; */
  font-weight: bold;
}
.artist {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.episodeCount {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #828282;
}
