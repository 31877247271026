.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.image {
  border-radius: 14px !important;
  flex-shrink: 0;
}
.podcastMeta {
  flex-grow: 1;
  padding-left: 2rem;
}
.podcastMeta > div:not(:last-child) {
  margin-bottom: 0.5rem;
}
.title {
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.2;
}
.subtitle {
  display: flex;
  color: #888888;
}
.summary {
  font-weight: 100;
  text-align: justify;
  line-height: 1.2rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: #828282;
}
.subtitle div:not(:last-child):after {
  content: "·";
  padding: 0 3px;
}
.actions {
  margin-top: 0.5rem;
}
.actions > *:not(:last-child) {
  margin-right: 5px;
}

.empty {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.episodes {
  overflow-y: auto;
  overflow-x: hidden;
}
.listContent {
  overflow-y: auto;
  overflow-x: hidden;
}
.episode {
  display: flex;
  margin: 0 0.5rem 0.5rem 0;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e2e2e2;
  align-items: center;
}
.episode .image {
  flex-shrink: 0;
}
.episodeMeta {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 1rem;
  overflow: hidden;
}
.episodeMeta .pubDate {
  font-size: 0.8rem;
  color: #828282;
}
.episodeTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.episodeTitle:hover {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: black;
  text-decoration-thickness: 2px;
}
.episodeSummary {
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 40px;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.25rem;
  display: -webkit-box;
  letter-spacing: 0.01428571em;
  font-size: 0.875rem;
  color: #828282;
  cursor: pointer;
}
.episodeSummary:hover {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 2px;
}
.episodeActions {
  margin-top: 5px;
}
.episodeActions > *:not(:last-child) {
  margin-right: 0.5rem;
}
.modalContent {
  display: flex;
  padding: 2rem !important;
}
.modalMeta {
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
}

.modalSummary * {
  line-height: 1.4rem;
}
.modalActions > *:not(:last-child) {
  margin-bottom: 1rem;
}
.modalActions button {
  width: 100% !important;
}
