.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.podcastTitle {
  display: flex;
  align-items: center;
}
.podcastTitle h1 {
  flex-grow: 1;
  margin-right: 1rem;
}
.empty {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.podcastList {
  display: flex;
  overflow-y: auto !important;
  min-height: 400px;
  margin-left: -30px;
  padding-left: 30px;
  overflow: hidden;
}

.podcastColumn {
  background-clip: padding-box;
}
