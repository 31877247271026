html,
body,
#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 1em;
}

::-webkit-scrollbar-track {
  border-radius: 50px;
  background-color: #cccccc;
}

::-webkit-scrollbar-thumb {
  background-color: #8b8b8b;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #323232;
  border-radius: 50px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

#player ::-webkit-scrollbar {
  width: 1em;
}

#player ::-webkit-scrollbar-track {
  border-radius: 50px;
  background-color: #939393;
}

#player ::-webkit-scrollbar-thumb {
  background-color: #323232;
  border-radius: 50px;
}
#player ::-webkit-scrollbar-thumb:hover {
  background-color: black;
}

#player ::-webkit-scrollbar-corner {
  background-color: transparent;
}
