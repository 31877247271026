.playerContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #c4c4c4;
  min-width: 406px;
  max-width: 406px;
  flex-shrink: 0;
}
.playerMeta {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding: 1rem 0;
  flex-direction: column;
  flex-basis: 20%;
  flex-shrink: 0;
}
.playerImage {
  flex-basis: 40%;
  flex-shrink: 0;
  padding: 10% 20%;
}
.playerContainer button {
  padding: 5px !important;
  background: transparent !important;
  border: none !important;
  margin: 7px;
}
.playerContainer button * {
  font-size: 1.2rem;
  line-height: 0 !important;
  font-weight: 500;
  color: #323232;
}
.playerContainer button.smallerBtn {
  width: 36px !important;
  height: 36px !important;
}
.playerContainer button.smallBtn {
  width: 48px !important;
  height: 48px !important;
}
.playerContainer button.bigBtn {
  width: 96px;
  height: 96px;
  border-radius: 100%;
}
.playerContainer button svg {
  width: 100% !important;
  height: 100% !important;
  fill: #323232;
}
.playerContainer button:hover svg {
  fill: black;
}
.playerTitle {
  text-align: center;
}
.playerSubtitle {
  text-align: center;
  color: black !important;
}
.playerSubtitle:hover {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 2px;
}
.playerSummary {
  flex-grow: 1;
  padding: 0 2rem;
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
}
.playerSummary p {
  padding: 0;
  margin: 0;
  font-size: 0.875rem;
}
.playerMainControls {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.playerSeeker {
  padding: 0 48px;
  height: 72px;
  flex-shrink: 0;
}
.playerAuxControls {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
  flex-shrink: 0;
}
.volumeSlider {
  max-width: 25%;
  height: 20px;
  margin-left: 12px;
}

.playbackSelector {
  width: 4rem;
}
